import {withCookies} from 'react-cookie';
import CloseButton from '../common/CloseButton';
import AppContext from '../../AppContext';
import PlansSection, {SingleProductPlansSection} from '../common/PlansSection';
import ProductsSection from '../common/ProductsSection';
import InputForm from '../common/InputForm';
import {ValidateInputForm} from '../../utils/form';
import {getSiteProducts, getSitePrices, hasMultipleProducts, isInviteOnlySite, getAvailableProducts, hasMultipleProductsFeature} from '../../utils/helpers';
import UnWalletButton, {unWalletExplanationURL} from '../common/UnWalletButton';
import siteLogo from '../../images/logo.png';

const React = require('react');

export const SignupPageStyles = `
    .gh-portal-signup-logo {
        position: relative;
        display: block;
        background-position: 50%;
        background-size: cover;
        border-radius: 2px;
        width: 112px;
        height: 31px;
        margin: 12px 0 10px;
    }

    .gh-portal-signup-header,
    .gh-portal-signin-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 32px 24px;
    }

    .gh-portal-signup-header .gh-portal-main-title,
    .gh-portal-signin-header .gh-portal-main-title {
        margin-top: 12px;
    }

    .gh-portal-signup-logo + .gh-portal-main-title {
        margin: 4px 0 0;
    }

    .gh-portal-signup-header .gh-portal-main-subtitle {
        font-size: 1.5rem;
        text-align: center;
        line-height: 1.45em;
        margin: 4px 0 0;
        color: var(--grey3);
    }

    .gh-portal-signup-header.nodivider {
        border: none;
        margin-bottom: 0;
    }

    .gh-portal-signup-message {
        display: flex;
        justify-content: center;
        color: var(--grey4);
        font-size: 1.3rem;
        letter-spacing: 0.2px;
        margin-top: 8px;
    }

    .gh-portal-signup-message button {
        font-size: 1.3rem;
        font-weight: 600;
        margin-left: 4px;
    }

    .gh-portal-signup-message button span {
        display: inline-block;
        padding-bottom: 2px;
        margin-bottom: -2px;
    }

    .gh-portal-content.signup {
        background: linear-gradient(#fff 30%,hsla(0,0%,100%,0)),
                    linear-gradient(hsla(0,0%,100%,0),#fff 70%) 0 100%,
                    linear-gradient(#fff,transparent),
                    linear-gradient(transparent,rgba(0,0,0,.06)) 0 100%;
        background-repeat: no-repeat;
        background-color: #fff;
        background-size: 100% 40px,100% 40px,100% 14px,100% 14px;
        background-attachment: local,local,scroll,scroll;
    }

    .gh-portal-content.signup.invite-only {
        background: none;
    }

    .gh-portal-signin-method {
        padding-top: 24px;
        padding-bottom: 24px;
        width: 100%;
        max-width: 420px;
    }

    footer.gh-portal-signup-footer,
    footer.gh-portal-signin-footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        padding-top: 24px;
        height: unset;
    }

    /* Needed to cover small horizontal line glitch by the scroll shadow */
    footer.gh-portal-signup-footer::before {
        position: absolute;
        content: "";
        top: -2px;
        left: 0;
        right: 0;
        height: 3px;
        background: #fff;
        z-index: 9999;
    }

    .gh-portal-content.signup,
    .gh-portal-content.signin {
        max-height: unset !important;
        padding-bottom: 0;
    }

    .gh-portal-content.signin {
        padding-bottom: 4px;
    }

    .gh-portal-content.signup.noplan {
        margin-bottom: -8px;
    }

    .gh-portal-content.signup.single-field {
        margin-bottom: 4px;
    }

    .gh-portal-content.signup.single-field .gh-portal-input,
    .gh-portal-content.signin .gh-portal-input {
        margin-bottom: 8px;
    }

    .gh-portal-content.signup.single-field + .gh-portal-signup-footer,
    footer.gh-portal-signin-footer {
        padding-top: 12px;
    }

    .gh-portal-content.signin .gh-portal-section {
        margin-bottom: 0;
    }

    footer.gh-portal-signin-footer {
        height: 80px;
    }

    footer.gh-portal-signup-footer.invite-only {
        height: unset;
    }

    footer.gh-portal-signup-footer.invite-only .gh-portal-signup-message {
        margin-top: 0;
    }

    .gh-portal-popup-wrapper.multiple-products .gh-portal-powered {
        display: flex;
        margin-top: 24px;
        margin-bottom: 0;
        padding-bottom: 0;
    }

    .gh-portal-invite-only-notification {
        margin: 8px 32px;
        padding: 0;
        text-align: center;
        color: var(--grey2);
    }

    .gh-portal-icon-invitation {
        width: 44px;
        height: 44px;
        margin: 12px 0 2px;
    }


    /* Multiple products signup */

    .gh-portal-popup-wrapper.signup.multiple-products .gh-portal-content,
    .gh-portal-popup-wrapper.signin.multiple-products .gh-portal-content {
        width: 100%;
        overflow: hidden;
        background: #fff;
    }

    .gh-portal-popup-wrapper.multiple-products .gh-portal-signin-header {
        padding-top: 18vmin;
    }

    .gh-portal-popup-wrapper.signin.multiple-products .gh-portal-popup-container {
        padding-bottom: 3vmin;
    }

    .gh-portal-popup-wrapper.multiple-products footer.gh-portal-signup-footer,
    .gh-portal-popup-wrapper.multiple-products footer.gh-portal-signin-footer {
        width: 100%;
        max-width: 420px;
        height: unset;
        padding: 0 !important;
        margin: 24px 32px;
    }


    .gh-portal-popup-wrapper.multiple-products footer.gh-portal-signin-footer {
        padding-top: 24px;
    }

    .gh-portal-powered.multiple-products {
        display: none;
    }

    @media (max-width: 480px) {
        .gh-portal-popup-wrapper.multiple-products footer.gh-portal-signup-footer,
        .gh-portal-popup-wrapper.multiple-products footer.gh-portal-signin-footer {
            max-width: unset;
            padding: 0 32px !important;
        }

        .gh-portal-popup-wrapper.multiple-products.preview footer.gh-portal-signup-footer,
        .gh-portal-popup-wrapper.multiple-products.preview footer.gh-portal-signin-footer {
            padding-bottom: 32px !important;
        }

        .gh-portal-popup-wrapper.signup.multiple-products.preview .gh-portal-content,
        .gh-portal-popup-wrapper.signin.multiple-products.preview .gh-portal-content {
            overflow: unset;
        }
    }

    @media (max-width: 390px) {
        .gh-portal-popup-wrapper.multiple-products footer.gh-portal-signup-footer,
        .gh-portal-popup-wrapper.multiple-products footer.gh-portal-signin-footer {
            padding: 0 24px !important;
        }
    }

    @media (min-width: 480px) and (max-width: 820px) {
        .gh-portal-powered.outside {
            left: 50%; 
            transform: translateX(-50%);
        }

        .gh-portal-popup-wrapper.signup {
            padding-bottom: 20vmin;
        }
    }

    @media (min-width: 480px) {
        .gh-portal-popup-wrapper:not(.multiple-products) .gh-portal-powered {
            display: none;
        }
    }
`;

class SignupPage extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            plan: 'free'
        };
    }

    componentDidMount() {
        const {member, unWalletTokenClaim, unWalletMember} = this.context;
        const {cookies} = this.props;
        if (member || unWalletMember) {
            this.context.onAction('switchPage', {
                page: 'accountHome'
            });
        }
        if (unWalletTokenClaim) {
            this.setState({
                email: unWalletTokenClaim.email
            }, () => {
                this.handleSignup();
                this.context.onAction('didLoginViaUnWallet', {cookies, email: unWalletTokenClaim.email, address: unWalletTokenClaim.sub});
            });
        }
        // Handle the default plan if not set
        this.handleSelectedPlan();
    }

    componentDidUpdate() {
        this.handleSelectedPlan();
    }

    handleSelectedPlan() {
        const {site, pageQuery} = this.context;
        const prices = getSitePrices({site, pageQuery});

        const selectedPriceId = this.getSelectedPriceId(prices, this.state.plan);
        if (selectedPriceId !== this.state.plan) {
            this.setState({
                plan: selectedPriceId
            });
        }
    }

    componentWillUnmount() {
        clearTimeout(this.timeoutId);
    }

    handleSignup(e) {
        if (e) {
            e.preventDefault();
        }
        this.setState((state) => {
            return {
                errors: ValidateInputForm({fields: this.getInputFields({state})})
            };
        }, () => {
            const {onAction} = this.context;
            const {name, email, plan, errors} = this.state;
            const hasFormErrors = (errors && Object.values(errors).filter(d => !!d).length > 0);
            if (!hasFormErrors) {
                onAction('signup', {name, email, plan});
                this.setState({
                    errors: {}
                });
            }
        });
    }

    handleUnWalletClick(e) {
        const {cookies} = this.props;
        e.preventDefault();
        this.context.onAction('signinUnWallet', {cookies});
    }

    handleInputChange(e, field) {
        const fieldName = field.name;
        const value = e.target.value;
        this.setState({
            [fieldName]: value
        });
    }

    handleSelectPlan = (e, priceId) => {
        e && e.preventDefault();
        // Hack: React checkbox gets out of sync with dom state with instant update
        this.timeoutId = setTimeout(() => {
            this.setState((prevState) => {
                return {
                    plan: priceId
                };
            });
        }, 5);
    }

    onKeyDown(e) {
        // Handles submit on Enter press
        if (e.keyCode === 13){
            this.handleSignup(e);
        }
    }

    getSelectedPriceId(prices = [], selectedPriceId) {
        if (!prices || prices.length === 0) {
            return 'free';
        }
        const hasSelectedPlan = prices.some((p) => {
            return p.id === selectedPriceId;
        });

        if (!hasSelectedPlan) {
            return prices[0].id || 'free';
        }

        return selectedPriceId;
    }

    getInputFields({state, fieldNames}) {
        const {portal_name: portalName} = this.context.site;
        const unWalletTokenClaim = this.context.unWalletTokenClaim;

        const errors = state.errors || {};
        const fields = [
            {
                type: 'email',
                value: state.email,
                placeholder: 'yoshiko.dentsu@dentsu.com',
                readonly: unWalletTokenClaim !== null,
                label: 'Eメール',
                name: 'email',
                required: true,
                tabindex: 2,
                errorMessage: errors.email || ''
            }
        ];

        /** Show Name field if portal option is set*/
        if (portalName) {
            fields.unshift({
                type: 'text',
                value: state.name,
                placeholder: '電通 佳子',
                label: 'お名前',
                name: 'name',
                required: true,
                tabindex: 1,
                errorMessage: errors.name || ''
            });
        }
        fields[0].autoFocus = true;
        if (fieldNames && fieldNames.length > 0) {
            return fields.filter((f) => {
                return fieldNames.includes(f.name);
            });
        }
        return fields;
    }

    renderExplanation() {
        return (
            <section>
                <div className='gh-portal-section'>
                    <b>unWallet</b> は、<b>NFT</b> に関する知識がなくても <b>NFT</b> を簡単に扱えるウォレットです。<b>unWallet</b> ログインによって、「記事を読んで情報を得る」という従来のメディア体験が「記事を読んで情報と資産（<b>NFT</b>）を得る」という <b>Web 3.0</b> のメディア体験に変わります。
                </div>
            </section>
        );
    }

    renderPlans() {
        const {site, pageQuery} = this.context;
        const prices = getSitePrices({site, pageQuery});
        if (hasMultipleProductsFeature({site})) {
            const availableProducts = getAvailableProducts({site});
            const product = availableProducts?.[0];
            return (
                <SingleProductPlansSection
                    product={product}
                    plans={prices}
                    selectedPlan={this.state.plan}
                    onPlanSelect={(e, id) => {
                        this.handleSelectPlan(e, id);
                    }}
                />
            );
        }
        return (
            <PlansSection
                plans={prices}
                selectedPlan={this.state.plan}
                onPlanSelect={(e, id) => {
                    this.handleSelectPlan(e, id);
                }}
            />
        );
    }

    renderProducts() {
        const {site} = this.context;
        const products = getSiteProducts({site});
        return (
            <>
                <ProductsSection
                    products={products}
                    onPlanSelect={this.handleSelectPlan}
                />
            </>
        );
    }

    renderUnWalletLink() {
        return (
            <div className='gh-portal-signup-message'>
                <a href={unWalletExplanationURL} target='_blank' onClick={() => {
                    window.open(unWalletExplanationURL, '_blank');   
                }}>unWalletとは?</a>
            </div>
        );
    }

    renderProductsOrPlans() {
        const {site} = this.context;
        if (hasMultipleProducts({site})) {
            return this.renderProducts();
        } else {
            return this.renderPlans();
        }
    }

    renderForm() {
        const fields = this.getInputFields({state: this.state});
        const {site, pageQuery} = this.context;

        if (isInviteOnlySite({site, pageQuery})) {
            return (
                <section>
                    <div className='gh-portal-section'>
                        <p className='gh-portal-invite-only-notification'>This site is invite-only, contact the owner for access.</p>
                    </div>
                </section>
            );
        }

        return (
            <section>
                <div className='gh-portal-section'>
                    <InputForm
                        fields={fields}
                        onChange={(e, field) => this.handleInputChange(e, field)}
                        onKeyDown={e => this.onKeyDown(e)}
                    />
                    {this.renderProductsOrPlans()}
                </div>
            </section>
        );
    }

    renderSiteLogo() {
        const logoStyle = {};

        if (siteLogo) {
            logoStyle.backgroundImage = `url(${siteLogo})`;
            return (
                <img className='gh-portal-signup-logo' src={siteLogo} alt={this.context.site.title} />
            );
        }
        return null;
    }
    
    renderFormHeader() {
        return (
            <header className='gh-portal-signup-header'>
                {this.renderSiteLogo()}
            </header>
        );
    }

    getClassNames() {
        const {site, pageQuery} = this.context;
        const plansData = getSitePrices({site, pageQuery});
        const fields = this.getInputFields({state: this.state});
        let sectionClass = '';
        let footerClass = '';

        if (plansData.length <= 1 || isInviteOnlySite({site})) {
            if ((plansData.length === 1 && plansData[0].type === 'free') || isInviteOnlySite({site, pageQuery})) {
                sectionClass = 'noplan';
                if (fields.length === 1) {
                    sectionClass = 'single-field';
                }
                if (isInviteOnlySite({site})) {
                    footerClass = 'invite-only';
                    sectionClass = 'invite-only';
                }
            } else {
                sectionClass = 'singleplan';
            }
        }

        return {sectionClass, footerClass};
    }

    renderMultipleProducts() {
        let {sectionClass, footerClass} = this.getClassNames();

        return (
            <>
                <div className={'gh-portal-content signup' + sectionClass}>
                    <CloseButton />
                    {this.renderFormHeader()}
                    {this.renderForm()}
                </div>
                <footer className={'gh-portal-signup-footer ' + footerClass}>
                    {this.renderSubmitButton()}
                    {this.renderLoginMessage()}
                </footer>
            </>
        );
    }

    renderSingleProduct() {
        let {sectionClass, footerClass} = this.getClassNames();

        return (
            <>
                <div className={'gh-portal-content signup ' + sectionClass}>
                    <CloseButton />
                    {this.renderFormHeader()}
                    {this.renderForm()}
                </div>
                <footer className={'gh-portal-signup-footer ' + footerClass}>
                    {this.renderSubmitButton()}
                    {this.renderLoginMessage()}
                </footer>
            </>
        );
    }

    render() {
        let {sectionClass, footerClass} = this.getClassNames();

        return (
            <>
                <div className={'gh-portal-content signup ' + sectionClass}>
                    <CloseButton />
                    {this.renderFormHeader()}
                    {this.renderExplanation()}
                </div>
                <footer className={'gh-portal-signup-footer ' + footerClass}>
                    <UnWalletButton onClick={e => this.handleUnWalletClick(e)} />
                    {this.renderUnWalletLink()}
                </footer>
            </>
        );
    }
}

export default withCookies(SignupPage);
