import {withCookies} from 'react-cookie';
import AppContext from '../../AppContext';
import MemberAvatar from '../common/MemberGravatar';
import ActionButton from '../common/ActionButton';
import CloseButton from '../common/CloseButton';
import {getMemberSubscription, hasOnlyFreePlan, isComplimentaryMember} from '../../utils/helpers';
import {getDateString} from '../../utils/date-time';
import {ReactComponent as LoaderIcon} from '../../images/icons/loader.svg';
import {useContext} from 'react';
import {unWalletSignInURL} from '../../components/common/UnWalletButton';
import {retrieveUnWalletAddressFromFirestore} from '../../firebase';

const React = require('react');

export const AccountHomePageStyles = `
    .gh-portal-account-main {
        background: var(--grey13);
        padding: 32px 32px 0;
        max-height: calc(100vh - 12vw - 104px);
    }

    .gh-portal-account-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 0 32px;
    }

    .gh-portal-account-header .gh-portal-avatar {
        margin: 6px 0 8px !important;
    }

    .gh-portal-account-data {
        margin-bottom: 32px;
    }

    footer.gh-portal-account-footer {
        display: flex;
        padding: 32px;
        height: 104px;
        border-top: 1px solid #eaeaea;
    }

    .gh-portal-account-footer.paid {
        margin-top: 12px;
    }

    .gh-portal-account-footermenu {
        display: flex;
        list-style: none;
        padding: 0;
        margin: 0;
    }

    .gh-portal-account-footerright {
        display: flex;
        flex-grow: 1;
        align-items: center;
        justify-content: flex-end;
    }

    .gh-portal-account-footermenu li {
        margin-right: 16px;
    }

    .gh-portal-account-footermenu li:last-of-type {
        margin-right: 0;
    }

    .gh-portal-freeaccount-newsletter {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 24px;
    }

    .gh-portal-freeaccount-newsletter .label {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }

    .gh-portal-free-ctatext {
        margin-top: -12px;
    }

    .gh-portal-cancelcontinue-container {
        margin: 24px 0 32px;
    }

    .gh-portal-billing-button-loader {
        width: 32px;
        height: 32px;
        margin-right: -3px;
        opacity: 0.6;
    }

    .gh-portal-product-icon {
        width: 52px;
        margin-right: 12px;
        border-radius: 2px;
    }
`;

const UserAvatar = ({avatar, brandColor}) => {
    return (
        <>
            <MemberAvatar gravatar={avatar} style={{userIcon: {color: brandColor, width: '56px', height: '56px', padding: '2px'}}} />
        </>
    );
};

const AccountFooter = ({handleSignout}) => {
    const supportAddressMail = `mailto:innovation-initiative@dentsu.co.jp`;
    return (
        <footer className='gh-portal-account-footer'>
            <ul className='gh-portal-account-footermenu'>
                <li><button className='gh-portal-btn' name='logout' aria-label='logout' onClick={e => handleSignout(e)}>ログアウト</button></li>
            </ul>
            <div className='gh-portal-account-footerright'>
                <ul className='gh-portal-account-footermenu'>
                    <li><a className='gh-portal-btn gh-portal-btn-branded' href={supportAddressMail} onClick={() => {
                        supportAddressMail && window.open(supportAddressMail);
                    }}>サポートへ連絡</a></li>
                </ul>
            </div>
        </footer>
    );
};

const UserHeader = () => {
    const {member, brandColor} = useContext(AppContext);
    const avatar = member ? member.avatar_image : null;
    return (
        <header className='gh-portal-account-header'>
            <UserAvatar avatar={avatar} brandColor={brandColor} />
            <h2 className="gh-portal-main-title">あなたのアカウント</h2>
        </header>
    );
};

const PaidAccountActions = () => {
    const {member, site, onAction} = useContext(AppContext);

    const onEditBilling = () => {
        const subscription = getMemberSubscription({member});
        onAction('editBilling', {subscriptionId: subscription.id});
    };

    const openUpdatePlan = () => {
        const {is_stripe_configured: isStripeConfigured} = site;
        if (isStripeConfigured) {
            onAction('switchPage', {
                page: 'accountPlan',
                lastPage: 'accountHome'
            });
        }
    };

    const PlanLabel = ({price, isComplimentary}) => {
        let label = '';
        if (price) {
            const {amount = 0, currency, interval} = price;
            let intervalDisplay = interval;
            if (interval === 'month') {
                intervalDisplay = '月';
            } else if (interval === 'year') {
                intervalDisplay = '年';
            }
            label = `${Intl.NumberFormat('ja', {currency, style: 'currency'}).format(amount)}/${intervalDisplay}`;
        }
        if (isComplimentary) {
            label = label ? `無料サービスの(${label})` : `無料`;
        }
        return (
            <p>
                {label}
            </p>
        );
    };

    const PlanUpdateButton = ({isComplimentary}) => {
        if (isComplimentary || hasOnlyFreePlan({site})) {
            return null;
        }
        return (
            <button className='gh-portal-btn gh-portal-btn-list' onClick={e => openUpdatePlan(e)}>変更</button>
        );
    };

    const CardLabel = ({defaultCardLast4}) => {
        if (defaultCardLast4) {
            const label = `**** **** **** ${defaultCardLast4}`;
            return (
                <p>
                    {label}
                </p>
            );
        }
        return null;
    };

    const BillingSection = ({defaultCardLast4, isComplimentary}) => {
        const {action} = useContext(AppContext);
        const label = action === 'editBilling:running' ? (
            <LoaderIcon className='gh-portal-billing-button-loader' />
        ) : '編集';
        if (isComplimentary) {
            return null;
        }

        return (
            <section>
                <div className='gh-portal-list-detail'>
                    <h3>課金情報</h3>
                    <CardLabel defaultCardLast4={defaultCardLast4} />
                </div>
                <button className='gh-portal-btn gh-portal-btn-list' onClick={e => onEditBilling(e)}>{label}</button>
            </section>
        );
    };

    const subscription = getMemberSubscription({member});
    const isComplimentary = isComplimentaryMember({member});
    if (subscription || isComplimentary) {
        const {
            plan,
            price,
            default_payment_card_last4: defaultCardLast4
        } = subscription || {};
        return (
            <>
                <section>
                    <div className='gh-portal-list-detail'>
                        <h3>購読プラン</h3>
                        <PlanLabel plan={plan} price={price} isComplimentary={isComplimentary} />
                    </div>
                    <PlanUpdateButton isComplimentary={isComplimentary} />
                </section>
                <BillingSection isComplimentary={isComplimentary} defaultCardLast4={defaultCardLast4} />
            </>
        );
    }
    return null;
};

const AccountActions = ({state}) => {
    const {member, onAction} = useContext(AppContext);
    const {name} = member ? member : {};
    const {unWalletAddress} = state;

    const openEditProfile = () => {
        onAction('switchPage', {
            page: 'accountProfile',
            lastPage: 'accountHome'
        });
    };

    return (
        <div>
            <div className='gh-portal-list'>
                <section style={unWalletAddress ? {} : {paddingBottom: '28px'}}>
                    <div className='gh-portal-list-detail'>
                        <h3>{(name ? name : 'アカウント')}</h3>
                        {unWalletAddress ? <p style={{overflowWrap: 'anywhere'}}>{unWalletAddress}</p> : <LoaderIcon className={'gh-portal-loadingicon dark'} />}
                    </div>
                    {member && <button className='gh-portal-btn gh-portal-btn-list' onClick={e => openEditProfile(e)}>編集</button>}
                </section>

                <PaidAccountActions />

                <section>
                    <div className='gh-portal-list-detail'>
                        <h3>unWalletのアカウント詳細は<a href={unWalletSignInURL} target='_blank' onClick={() => {
                            window.open(unWalletSignInURL, '_blank');
                        }}>こちら</a></h3>
                    </div>
                </section>
            </div>
            {/* <ProductList openUpdatePlan={openUpdatePlan}></ProductList> */}
        </div>
    );
};

const SubscribeButton = () => {
    const {site, action, brandColor, onAction} = useContext(AppContext);
    const {is_stripe_configured: isStripeConfigured} = site;

    if (!isStripeConfigured || hasOnlyFreePlan({site})) {
        return null;
    }
    const isRunning = ['checkoutPlan:running'].includes(action);

    const openPlanPage = () => {
        onAction('switchPage', {
            page: 'accountPlan',
            lastPage: 'accountHome'
        });
    };
    return (
        <ActionButton
            isRunning={isRunning}
            label="購読プランを見る"
            onClick={() => openPlanPage()}
            brandColor={brandColor}
            style={{width: '100%'}}
        />
    );
};

const AccountWelcome = () => {
    const {member, site} = useContext(AppContext);
    const {is_stripe_configured: isStripeConfigured} = site;

    if (!isStripeConfigured || hasOnlyFreePlan({site})) {
        return null;
    }
    const subscription = getMemberSubscription({member});
    const isComplimentary = isComplimentaryMember({member});
    if (isComplimentary && !subscription) {
        return null;
    }
    if (subscription) {
        const currentPeriodEnd = subscription?.current_period_end;
        if (subscription?.cancel_at_period_end) {
            return null;
        }
        return (
            <div className='gh-portal-section'>
                <p className='gh-portal-text-center gh-portal-free-ctatext'>購読プランは{getDateString(currentPeriodEnd)}に更新されます</p>
            </div>
        );
    }

    return (
        <div className='gh-portal-section'>
            <p className='gh-portal-text-center gh-portal-free-ctatext'>現在無料購読プランをお使いです。有料プランにアップグレードして全てのコンテンツにアクセスできます。</p>
            <SubscribeButton />
        </div>
    );
};

const ContinueSubscriptionButton = () => {
    const {member, onAction, action, brandColor} = useContext(AppContext);
    const subscription = getMemberSubscription({member});
    if (!subscription) {
        return null;
    }

    // To show only continue button and not cancellation
    if (!subscription.cancel_at_period_end) {
        return null;
    }
    const label = subscription.cancel_at_period_end ? '有料購読を続ける' : '有料購読を中止する';
    const isRunning = ['cancelSubscription:running'].includes(action);
    const disabled = (isRunning) ? true : false;
    const isPrimary = !!subscription.cancel_at_period_end;

    const CancelNotice = () => {
        if (!subscription.cancel_at_period_end) {
            return null;
        }
        const currentPeriodEnd = subscription.current_period_end;
        return (
            <p className='gh-portal-text-center gh-portal-free-ctatext'>有料購読プランは{getDateString(currentPeriodEnd)}まで有効です。</p>
        );
    };

    return (
        <div className='gh-portal-cancelcontinue-container'>
            <CancelNotice />
            <ActionButton
                onClick={(e) => {
                    onAction('continueSubscription', {
                        subscriptionId: subscription.id
                    });
                }}
                isRunning={isRunning}
                disabled={disabled}
                isPrimary={isPrimary}
                brandColor={brandColor}
                label={label}
                style={{
                    width: '100%'
                }}
            />
        </div>
    );
};

const AccountMain = ({state}) => {
    return (
        <div className='gh-portal-content gh-portal-account-main'>
            <CloseButton />
            <UserHeader />
            <section className='gh-portal-account-data'>
                <AccountWelcome />
                <ContinueSubscriptionButton />
                <AccountActions state={state}/>
            </section>
        </div>
    );
};

class AccountHomePage extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        const {member} = this.context;
        if (!member) {
            this.context.onAction('switchPage', {
                page: 'signin'
            });
        }
        if (member) {
            retrieveUnWalletAddressFromFirestore({member}).then((unWalletAddress) => {
                this.setState({
                    unWalletAddress
                });
            });
        }
    }

    handleSignout(e) {
        e.preventDefault();
        const {cookies} = this.props;
        this.context.onAction('signout', {cookies});
    }

    render() {
        const {member} = this.context;
        const supportAddress = 'innovation-initiative@dentsu.co.jp';
        if (!member) {
            return null;
        }
        return (
            <div className='gh-portal-account-wrapper'>
                <AccountMain state={this.state}/>
                <AccountFooter
                    onClose={() => this.context.onAction('closePopup')}
                    handleSignout={e => this.handleSignout(e)}
                    supportAddress={supportAddress}
                />
            </div>
        );
    }
}

export default withCookies(AccountHomePage);
