import {UnWallet} from 'unwallet-client-sdk';
import crypto from 'crypto';
import {createPopupNotification, getMemberEmail, getMemberName, removePortalLinkFromUrl} from './utils/helpers';

// const homeURL = 'http://localhost:2369/'; // dev
const homeURL = 'https://innovation.dentsu.com/';

function switchPage({data}) {
    return {
        page: data.page,
        popupNotification: null,
        lastPage: data.lastPage || null
    };
}

function togglePopup({state}) {
    return {
        showPopup: !state.showPopup
    };
}

function openPopup({data}) {
    return {
        showPopup: true,
        page: data.page,
        ...(data.pageQuery ? {pageQuery: data.pageQuery} : {}),
        ...(data.pageData ? {pageData: data.pageData} : {})
    };
}

function back({state}) {
    if (state.lastPage) {
        return {
            page: state.lastPage
        };
    } else {
        return closePopup({state});
    }
}

function closePopup({state}) {
    removePortalLinkFromUrl();
    return {
        showPopup: false,
        lastPage: null,
        pageQuery: '',
        popupNotification: null,
        page: state.page === 'magiclink' ? '' : state.page
    };
}

async function didLoginViaUnWallet({data, api}) {
    const unWalletAddress = data.address;
    const cookies = data.cookies;
    cookies.set('unwallet_address', unWalletAddress);
    if (data.email) {
        const {url} = await api.member.automaticLogin({email: data.email}); // here we may not have data.email if user uses apple id login, this line throws exception？？
        window.location.href = url;
    } else {
        // make a fake email address for apple id login as we don't get data.email
        const {url} = await api.member.hashThenAutomaticLogin({unWalletAddress});
        window.location.href = url;
    }
}

function openNotification({data}) {
    return {
        showNotification: true,
        ...data
    };
}

function closeNotification({state}) {
    return {
        showNotification: false
    };
}

async function signout({data, api, state}) {
    try {
        if (state.member) {
            await api.member.signout();
            return {
                action: 'signout:success'
            };
        } else if (state.unWalletMember) {
            data.cookies.remove('unwallet_address');
            return {
                action: 'signout:success',
                showPopup: false
            };
        }
    } catch (e) {
        return {
            action: 'signout:failed',
            popupNotification: createPopupNotification({
                type: 'signout:failed', autoHide: false, closeable: true, state, status: 'error',
                message: 'ログアウトに失敗しました。再試行ください。'
            })
        };
    }
}

async function signin({data, api, state}) {
    try {
        await api.member.sendMagicLink(data);
        return {
            page: 'magiclink',
            lastPage: 'signin'
        };
    } catch (e) {
        return {
            action: 'signin:failed',
            popupNotification: createPopupNotification({
                type: 'signin:failed', autoHide: false, closeable: true, state, status: 'error',
                message: 'ログインに失敗しました。再試行ください。'
            })
        };
    }
}

async function signinUnWallet({state, data}) {
    try {
        const nonce = crypto.randomBytes(16).toString('hex');
        const unWallet = await UnWallet.init({
            // clientID: '24104626207546', // dev
            // env: 'dev'
            clientID: '27982796154207' // prod
        });
        data.cookies.remove('unwallet_nonce');
        data.cookies.set('unwallet_nonce', nonce, {path: '/'});
        let callbackURL = homeURL;
        unWallet.authorize({
            redirectURL: callbackURL,
            nonce
        });
    } catch (e) {
        return {
            action: 'signinUnWallet:failed',
            popupNotification: createPopupNotification({
                type: 'signinUnWallet:failed', autoHide: false, closeable: true, state, status: 'error',
                message: 'unWalletを使ったログインに失敗しました。再試行ください。'
            })
        };
    }    
} 

async function signup({data, state, api}) {
    try {
        const {plan, email, name, offerId} = data;
        if (plan.toLowerCase() === 'free') {
            await api.member.sendMagicLink(data);
        } else {
            await api.member.checkoutPlan({plan, email, name, offerId});
        }
        return {
            page: 'magiclink',
            lastPage: 'signup'
        };
    } catch (e) {
        return {
            action: 'signup:failed',
            popupNotification: createPopupNotification({
                type: 'signup:failed', autoHide: false, closeable: true, state, status: 'error',
                message: '購読に失敗しました。再試行ください。'
            })
        };
    }
}

async function checkoutPlan({data, state, api}) {
    try {
        const {plan, offerId} = data;
        await api.member.checkoutPlan({
            plan,
            offerId,
            metadata: {
                checkoutType: 'upgrade'
            }
        });
    } catch (e) {
        return {
            action: 'checkoutPlan:failed',
            popupNotification: createPopupNotification({
                type: 'checkoutPlan:failed', autoHide: false, closeable: true, state, status: 'error',
                message: '購入に失敗しました。再試行ください。'
            })
        };
    }
}

async function updateSubscription({data, state, api}) {
    try {
        const {plan, planId, subscriptionId, cancelAtPeriodEnd} = data;
        await api.member.updateSubscription({
            planName: plan, subscriptionId, cancelAtPeriodEnd, planId: planId
        });
        const member = await api.member.sessionData();
        const action = 'updateSubscription:success';
        return {
            action,
            popupNotification: createPopupNotification({
                type: action, autoHide: true, closeable: true, state, status: 'success',
                message: '購読プランはアップデートされました'
            }),
            page: 'accountHome',
            member: member
        };
    } catch (e) {
        return {
            action: 'updateSubscription:failed',
            popupNotification: createPopupNotification({
                type: 'updateSubscription:failed', autoHide: false, closeable: true, state, status: 'error',
                message: '購読プランアップデートに失敗しました。再試行ください。'
            })
        };
    }
}

async function cancelSubscription({data, state, api}) {
    try {
        const {subscriptionId, cancellationReason} = data;
        await api.member.updateSubscription({
            subscriptionId, smartCancel: true, cancellationReason
        });
        const member = await api.member.sessionData();
        const action = 'cancelSubscription:success';
        return {
            action,
            page: 'accountHome',
            member: member
        };
    } catch (e) {
        return {
            action: 'cancelSubscription:failed',
            popupNotification: createPopupNotification({
                type: 'cancelSubscription:failed', autoHide: false, closeable: true, state, status: 'error',
                message: '購読プランのキャンセルに失敗しました。再試行ください。'
            })
        };
    }
}

async function continueSubscription({data, state, api}) {
    try {
        const {subscriptionId} = data;
        await api.member.updateSubscription({
            subscriptionId, cancelAtPeriodEnd: false
        });
        const member = await api.member.sessionData();
        const action = 'continueSubscription:success';
        return {
            action,
            page: 'accountHome',
            member: member
        };
    } catch (e) {
        return {
            action: 'continueSubscription:failed',
            popupNotification: createPopupNotification({
                type: 'continueSubscription:failed', autoHide: false, closeable: true, state, status: 'error',
                message: '購読のキャンセルに失敗しました。再試行ください。'
            })
        };
    }
}

async function editBilling({data, state, api}) {
    try {
        await api.member.editBilling(data);
    } catch (e) {
        return {
            action: 'editBilling:failed',
            popupNotification: createPopupNotification({
                type: 'editBilling:failed', autoHide: false, closeable: true, state, status: 'error',
                message: '課金情報の変更に失敗しました。再試行ください。'
            })
        };
    }
}

async function clearPopupNotification() {
    return {
        popupNotification: null
    };
}

async function updateNewsletter({data, state, api}) {
    try {
        const {subscribed} = data;
        const member = await api.member.update({subscribed});
        if (!member) {
            throw new Error('Failed to update newsletter');
        }
        const action = 'updateNewsletter:success';
        return {
            action,
            member: member,
            popupNotification: createPopupNotification({
                type: action, autoHide: true, closeable: true, state, status: 'success',
                message: 'ニュースレター設定がアップデートされました。'
            })
        };
    } catch (e) {
        return {
            action: 'updateNewsletter:failed',
            popupNotification: createPopupNotification({
                type: 'updateNewsletter:failed', autoHide: true, closeable: true, state, status: 'error',
                message: 'ニュースレター設定のアップデートに失敗しました。'
            })
        };
    }
}

async function updateMemberEmail({data, state, api}) {
    const {email} = data;
    const originalEmail = getMemberEmail({member: state.member});
    if (email !== originalEmail) {
        try {
            await api.member.updateEmailAddress({email});
            return {
                success: true
            };
        } catch (err) {
            return {
                success: false,
                error: err
            };
        }
    }
    return null;
}

async function updateMemberData({data, state, api}) {
    const {name} = data;
    const originalName = getMemberName({member: state.member});
    if (originalName !== name) {
        try {
            const member = await api.member.update({name});
            if (!member) {
                throw new Error('メンバーのアップデートに失敗しました');
            }
            return {
                member,
                success: true
            };
        } catch (err) {
            return {
                success: false,
                error: err
            };
        }
    }
    return null;
}

async function refreshMemberData({state, api}) {
    if (state.member) {
        try {
            const member = await api.member.sessionData();
            if (member) {
                return {
                    member,
                    success: true
                };
            }
            return null;
        } catch (err) {
            return {
                success: false,
                error: err
            };
        }
    }
    return null;
}

async function updateProfile({data, state, api}) {
    const [dataUpdate, emailUpdate] = await Promise.all([updateMemberData({data, state, api}), updateMemberEmail({data, state, api})]);
    if (dataUpdate && emailUpdate) {
        if (emailUpdate.success) {
            return {
                action: 'updateProfile:success',
                ...(dataUpdate.success ? {member: dataUpdate.member} : {}),
                page: 'accountHome',
                popupNotification: createPopupNotification({
                    type: 'updateProfile:success', autoHide: true, closeable: true, status: 'success', state,
                    message: 'Eメールが変更されたかメールボックスをご確認ください。'
                })
            };
        }
        const message = !dataUpdate.success ? 'アカウント情報の更新に失敗しました。' : '確認メールの送信に失敗しました。';

        return {
            action: 'updateProfile:failed',
            ...(dataUpdate.success ? {member: dataUpdate.member} : {}),
            popupNotification: createPopupNotification({
                type: 'updateProfile:failed', autoHide: true, closeable: true, status: 'error', message, state
            })
        };
    } else if (dataUpdate) {
        const action = dataUpdate.success ? 'updateProfile:success' : 'updateProfile:failed';
        const status = dataUpdate.success ? 'success' : 'error';
        const message = !dataUpdate.success ? 'アカウント情報の更新に失敗しました。' : 'アカウント情報が更新されしました。';
        return {
            action,
            ...(dataUpdate.success ? {member: dataUpdate.member} : {}),
            ...(dataUpdate.success ? {page: 'accountHome'} : {}),
            popupNotification: createPopupNotification({
                type: action, autoHide: dataUpdate.success, closeable: true, status, state, message
            })
        };
    } else if (emailUpdate) {
        const action = emailUpdate.success ? 'updateProfile:success' : 'updateProfile:failed';
        const status = emailUpdate.success ? 'success' : 'error';
        const message = !emailUpdate.success ? '確認メールの送信に失敗しました。' : 'Eメールが変更されたかメールボックスをご確認ください。';
        return {
            action,
            ...(emailUpdate.success ? {page: 'accountHome'} : {}),
            popupNotification: createPopupNotification({
                type: action, autoHide: emailUpdate.success, closeable: true, status, state, message
            })
        };
    }
    return {
        action: 'updateProfile:success',
        page: 'accountHome',
        popupNotification: createPopupNotification({
            type: 'updateProfile:success', autoHide: true, closeable: true, status: 'success', state,
            message: 'アカウント情報が更新されしました。'
        })
    };
}

const Actions = {
    togglePopup,
    openPopup,
    closePopup,
    switchPage,
    openNotification,
    closeNotification,
    back,
    signout,
    signin,
    signinUnWallet,
    signup,
    didLoginViaUnWallet,
    updateSubscription,
    cancelSubscription,
    continueSubscription,
    updateNewsletter,
    updateProfile,
    refreshMemberData,
    clearPopupNotification,
    editBilling,
    checkoutPlan
};

/** Handle actions in the App, returns updated state */
export default async function ActionHandler({action, data, state, api}) {
    const handler = Actions[action];
    if (handler) {
        return await handler({data, state, api}) || {};
    }
    return {};
}
