const React = require('react');

export const DividerStyles = `
    .gh-portal-signin-container{
        display: flex;
        align-items: center;
        width: 100%;
        max-width: 420px;
        margin-top: 1em;
        margin-bottom: 1em;
    }
      
    .gh-portal-signin-border{
        border-bottom: 1px solid black;
        width: 37%;
    }
      
    .gh-portal-signin-content {
        padding: 0 10px 0 10px;
    }
`;

const Divider = ({children}) => {
    return (
        <div className="gh-portal-signin-container">
            <div className="gh-portal-signin-border" />
            <span className="gh-portal-signin-content">
                {children}
            </span>
            <div className="gh-portal-signin-border" />
        </div>
    );
};

export default Divider;