import React, {useContext} from 'react';
import AppContext from '../../AppContext';
import ActionButton from './ActionButton';

const UnWalletButton = (props) => {
    const {action} = useContext(AppContext);
    const {onClick} = props;
   
    let retry = false;
    const isRunning = (action === 'signinUnWallet:running');
    const disabled = isRunning ? true : false;
    if (action === 'signinUnWallet:failed') {
        retry = true;
    }
    return (
        <ActionButton
            retry={retry}
            style={{width: '100%'}}
            onClick={onClick}
            disabled={disabled}
            brandColor={'#3a8091'}
            image={`url("https://cdn.unwallet.world/assets/img/logo-ent_white.png")`}
            isRunning={isRunning}
        />
    );
};

const unWalletSignInURL = 'https://id.unwallet.world/signin';
// const unWalletSignInURL = 'https://id.unwallet.dev/signin'; // dev
const unWalletExplanationURL = 'https://www.about.unwallet.world/';

export default UnWalletButton;
export {unWalletSignInURL, unWalletExplanationURL};