import {withCookies} from 'react-cookie';
import ActionButton from '../common/ActionButton';
import CloseButton from '../common/CloseButton';
import AppContext from '../../AppContext';
import {ValidateInputForm} from '../../utils/form';
import UnWalletButton from '../common/UnWalletButton';

const React = require('react');

class SigninPage extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.state = {
            email: ''
        };
    }

    componentDidMount() {
        const {member, unWalletMember} = this.context;
        if (member || unWalletMember) {
            this.context.onAction('switchPage', {
                page: 'accountHome'
            });
        }
    }

    handleSignin(e) {
        e.preventDefault();
        this.setState((state) => {
            return {
                errors: ValidateInputForm({fields: this.getInputFields({state})})
            };
        }, () => {
            const {email, errors} = this.state;
            const hasFormErrors = (errors && Object.values(errors).filter(d => !!d).length > 0);
            if (!hasFormErrors) {
                this.context.onAction('signin', {email});
            }
        });
    }

    handleUnWalletClick(e) {
        const {cookies} = this.props;
        e.preventDefault();
        this.context.onAction('signinUnWallet', {cookies});
    }

    handleInputChange(e, field) {
        const fieldName = field.name;
        this.setState({
            [fieldName]: e.target.value
        });
    }

    onKeyDown(e) {
        // Handles submit on Enter press
        if (e.keyCode === 13){
            this.handleSignin(e);
        }
    }

    getInputFields({state}) {
        const errors = state.errors || {};
        const fields = [
            {
                type: 'email',
                value: state.email,
                placeholder: 'yoshiko.dentsu@dentsu.com',
                label: 'Eメール',
                name: 'email',
                required: true,
                errorMessage: errors.email || '',
                autoFocus: true
            }
        ];
        return fields;
    }

    renderSubmitButton() {
        const {action} = this.context;
        let retry = false;
        const isRunning = (action === 'signin:running');
        let label = isRunning ? 'ログイン用リンクを送信中...' : '進む';
        const disabled = isRunning ? true : false;
        if (action === 'signin:failed') {
            label = '再試行';
            retry = true;
        }
        return (
            <ActionButton
                retry={retry}
                style={{width: '100%'}}
                onClick={e => this.handleSignin(e)}
                disabled={disabled}
                brandColor={this.context.brandColor}
                label={label}
                isRunning={isRunning}
            />
        );
    }

    renderSignupMessage() {
        const brandColor = this.context.brandColor;
        return (
            <div className='gh-portal-signup-message'>
                <div>アカウントをお持ちでないですか?</div>
                <button className='gh-portal-btn gh-portal-btn-link' style={{color: brandColor}} onClick={() => this.context.onAction('switchPage', {page: 'signup'})}><span>購読する</span></button>
            </div>
        );
    }

    renderExplanation() {
        return (
            <section>
                <div className='gh-portal-section'>
                    <b>unWallet</b> は、<b>NFT</b> に関する知識がなくても <b>NFT</b> を簡単に扱えるウォレットです。<b>unWallet</b> ログインによって、「記事を読んで情報を得る」という従来のメディア体験が「記事を読んで情報と資産（<b>NFT</b>）を得る」という <b>Web 3.0</b> のメディア体験に変わります。
                </div>
            </section>
        );
    }

    renderFormHeader() {
        const siteTitle = this.context.site.title || 'Site Title';

        return (
            <header className='gh-portal-signin-header'>
                <h2 className="gh-portal-main-title">{siteTitle}にログイン</h2>
            </header>
        );
    }

    render() {
        return (
            <>
                <div className='gh-portal-content signin'>
                    <CloseButton />
                    {this.renderFormHeader()}
                    {this.renderExplanation()}
                    <UnWalletButton onClick={e => this.handleUnWalletClick(e)} />
                </div>
                <footer className='gh-portal-signin-footer'>
                    {this.renderSignupMessage()}
                </footer>
            </>
        );
    }
}

export default withCookies(SigninPage);