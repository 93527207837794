import {withCookies} from 'react-cookie';
import AppContext from '../../AppContext';
import {ReactComponent as LoaderIcon} from '../../images/icons/loader.svg';
const React = require('react');
class LoadingPage extends React.Component {
    static contextType = AppContext;

    componentDidMount() {
        const {cookies} = this.props;
        const {unWalletTokenClaim} = this.context;
        if (unWalletTokenClaim) {
            this.context.onAction('didLoginViaUnWallet', {cookies, email: unWalletTokenClaim.email, address: unWalletTokenClaim.sub});
        }
    }

    render() {
        return (
            <div style={{display: 'flex', flexDirection: 'column', color: '#313131'}}>
                <div style={{paddingLeft: '16px', paddingRight: '16px', paddingTop: '12px', height: '50px'}}>
                    <LoaderIcon className={'gh-portal-loadingicon dark'} />
                </div>
            </div>
        );
    }
}

export default withCookies(LoadingPage);