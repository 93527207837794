import {initializeApp} from 'firebase/app';
import {
    getFirestore, 
    doc, 
    getDoc, 
    setDoc, 
    // connectFirestoreEmulator // dev
} from 'firebase/firestore';

const firebaseConfig = {
    apiKey: 'AIzaSyBgTz1bc6kp92Rzd7ImASM4LBY2-SuAnv8',
    authDomain: 'dii-web-badges.firebaseapp.com',
    projectId: 'dii-web-badges',
    storageBucket: 'dii-web-badges.appspot.com',
    messagingSenderId: '7214729781',
    appId: '1:7214729781:web:e17098eaa88ccd7730ff7c',
    measurementId: 'G-X4TP2FH1C3'
};

const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);
// connectFirestoreEmulator(firestore, 'localhost', 8080); // dev

const sendGhostMemberUUIDAndUnWalletAddressToFirestore = ({memberUUID, unWalletAddress}) => {
    if (memberUUID && unWalletAddress) {
        setDoc(doc(firestore, 'users', memberUUID), {
            unWalletAddress,
            lastLogin: new Date()
        });
    }
};

const retrieveUnWalletAddressFromFirestore = async ({member}) => {
    const uuid = member.uuid;
    if (uuid) {
        const ref = doc(firestore, 'users', uuid);
        const docSnap = await getDoc(ref);
        if (docSnap.exists()) {
            const unWalletAddress = docSnap.data().unWalletAddress || '';
            return unWalletAddress;
        }
    }
    return '';
};

export {firestore, sendGhostMemberUUIDAndUnWalletAddressToFirestore, retrieveUnWalletAddressFromFirestore};